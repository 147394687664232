import React, { useState, useEffect } from "react";
import axios from "axios";
import { IoMdHome } from "react-icons/io";
import Config from "../config/config.js";
import sendMongoAnalytics from "../utils/sendMongoAnalytics.tsx";

function Navbar({ onSelectClass }) {
  const [classData, setClassData] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");

  const handleClassChange = (e) => {
    sendMongoAnalytics("class_changed", {
      oldClass: selectedClass,
      newClass: e.target.value,
    });
    const newClass = e.target.value;
    setSelectedClass(newClass);

    axios
      .post(`${Config.BACKEND_URL}/update-class`, { class_name: newClass })
      .then((response) => {
        console.log(response.data.message);
        onSelectClass(newClass);
      })
      .catch((error) => {
        console.error("Error updating class:", error);
      });
  };

  useEffect(() => {
    setClassData(JSON.parse(localStorage.getItem("batchAccess")));
    setSelectedClass(
      JSON.parse(localStorage.getItem("batchAccess"))[0].className
    );
    localStorage.setItem(
      "selectedClass",
      JSON.parse(localStorage.getItem("batchAccess"))[0].className
    );
  }, []);

  return (
    <div className="flex justify-between items-center px-32 h-20 p-4 bg-[#1B2124] text-white">
      <div className="flex items-center gap-8">
        <IoMdHome color="white" size={30} />
        <p>Attendance System</p>
      </div>
      <div className="flex items-center gap-4">
        <select
          className="text-white bg-transparent w-52 py-1.5 border border-white rounded-md px-3"
          value={selectedClass}
          onChange={handleClassChange}
        >
          {
            // classData.map((batch) => (
            //   <option key={batch._id} value={batch.className}>{batch.className}</option>
            // ))
            classData
              .reduce((acc, batch) => {
                if (!acc.some((item) => item.className === batch.className)) {
                  acc.push(batch);
                }
                return acc;
              }, [])
              .map((batch) => (
                <option key={batch._id} value={batch.className}>
                  {batch.className}
                </option>
              ))
          }
        </select>
      </div>
    </div>
  );
}

export default Navbar;
