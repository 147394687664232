import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PasswordLogin = () => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();

  const verifyCredentials = async () => {
    setSpinner(true);
    try {
      const res = await axios.post(
        "https://tv-app-analytics-backend.betterpw.live/v1/auth/login",
        {
          username: userId,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.data.user.hasAccess) {
        console.log("Credentials verified!", res.data.data.user);
        localStorage.setItem("loggedIn", "true");
        localStorage.setItem("loginDetails", JSON.stringify(res.data.data.user));
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("schoolId", res.data.data.user._id);
        axios.get(`https://tv-app-analytics-backend.betterpw.live/v1/batch-access/school/${res.data.data.user._id}`)
        .then((res) => {
          localStorage.setItem("batchAccess", JSON.stringify(res.data.data));
        }).catch((err) => {
          console.error("Error getting batch access:", err);
        })
        navigate("/home");
      } else {
        alert("Invalid Credentials");
      }
    } catch (err) {
      console.error("Error verifying credentials:", err);
      alert("Invalid Credentials");
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn");
    if (loggedIn) {
      navigate("/home");
    }
  }, [navigate]);

  return (
    <div
      className="min-h-screen flex items-center justify-center"
      style={{
        backgroundColor: "#141414",
        backgroundImage: `url('/path-to-background-image.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="bg-gray-800 p-8 rounded-lg shadow-md max-w-sm w-full">
        <h1 className="text-2xl font-bold mb-6 text-center text-white">
          Welcome to PW TV Remote App
        </h1>
        <p className="mb-4 text-center text-gray-400">
          Enter ID and Password to Login
        </p>
        <input
          className="w-full p-2 mb-4 border border-gray-600 rounded bg-gray-700 text-white placeholder-gray-400"
          type="text"
          placeholder="Enter ID"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
        />
        <input
          className="w-full p-2 mb-4 border border-gray-600 rounded bg-gray-700 text-white placeholder-gray-400"
          type="password"
          placeholder="Enter Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          className={`w-full p-2 rounded ${spinner ? "bg-blue-600" : "bg-blue-500 hover:bg-blue-600"} text-white`}
          onClick={verifyCredentials}
          disabled={spinner}
        >
          {spinner ? <span>Loading...</span> : "Login"}
        </button>
        <p className="mt-4 text-center text-gray-400">
          I agree to all the{" "}
          <a href="https://www.pw.live/privacy-policy" target="_blank" className="font-bold text-blue-400">
            Terms & Conditions*
          </a>
        </p>
      </div>
    </div>
  );
};

export default PasswordLogin;
