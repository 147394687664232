import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import S3 from 'aws-sdk/clients/s3.js';
import Config from '../config/config';
import sendMongoAnalytics from '../utils/sendMongoAnalytics.tsx';

const s3 = new S3({
  endpoint: "https://sin1.contabostorage.com/school-attendance",
  accessKeyId: "f21439d90fd89c15e621536b99d3ec8b",
  secretAccessKey: "8acfc04a00cfa868bdfb5b53622fd97d",
  s3BucketEndpoint: true,
  signatureVersion: "v4",
});

const uploadBase64File = async (base64String, fileName, contentType, folder = '') => {
  const base64Data = base64String.replace(/^data:image\/\w+;base64,/, "");
  const binaryString = atob(base64Data);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const key = folder ? `${folder}/${fileName}` : fileName;

  const params = {
    Bucket: 'school-attendance',
    Key: key,
    Body: bytes.buffer,
    ContentEncoding: 'base64',
    ContentType: contentType
  };

  try {
    const data = await s3.upload(params).promise();
    console.log('File uploaded successfully:', data.Location);
    return data.Location;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

const readFiles = async (prefix = '') => {
  const params = {
    Bucket: 'school-attendance',
    Prefix: prefix
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    console.log('Files in bucket:', data.Contents);
    return data.Contents;
  } catch (error) {
    console.error('Error reading files:', error);
    throw error;
  }
};

const VideoCapture = ({ selectedClass }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [name, setName] = useState('');
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [attendance, setAttendance] = useState([]);

  useEffect(() => {
    localStorage.getItem('loggedIn') || window.location.replace('/');
    startVideoCapture();
  }, []);

  useEffect(() => {
    getAttendanceData();
  }, [student, selectedClass]);

  const startVideoCapture = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      captureFrames();
    } catch (err) {
      console.error('Error accessing camera:', err);
    }
  };

  const captureFrames = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const getBase64Image = () => {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // Flip the canvas context horizontally
        ctx.save();
        ctx.scale(-1, 1);
        ctx.translate(-canvas.width, 0);

        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        ctx.restore();

        const base64 = canvas.toDataURL('image/jpeg');
        sendFrameToBackend(base64);
      }
      setTimeout(getBase64Image, 1000);
    };

    getBase64Image();
  };

  const sendFrameToBackend = (imageData) => {
    const formData = new FormData();
    formData.append('image_data', imageData);

    axios.post(`${Config.BACKEND_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        setStudent(response?.data?.recognized_names || []);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  const captureMultipleFrames = async (name) => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const formData = new FormData();
    formData.append('name', name);

    const getBase64Images = async () => {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        for (let i = 0; i < 5; i++) {
          // Flip the canvas context horizontally
          ctx.save();
          ctx.scale(-1, 1);
          ctx.translate(-canvas.width, 0);

          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          ctx.restore();

          const base64 = canvas.toDataURL('image/jpeg');
          formData.append(`image${i}`, base64.split(',')[1]);
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        sendFormDataToBackend(formData);
      } else {
        setTimeout(getBase64Images, 3000);
      }
    };

    getBase64Images();
  };

  const sendFormDataToBackend = async (formData) => {
    setLoading(true);
    try {
      const response = await fetch(`${Config.BACKEND_URL}/register`, {
        method: 'POST',
        body: formData,
      });
      sendMongoAnalytics('student_registered', {})
      toast.success("Registered Student Successfully");
      // Upload the initial frame to S3
      const base64Image = canvasRef.current.toDataURL('image/png');
      await uploadBase64File(base64Image, `${name}.png`, 'image/png', selectedClass);
      setName('');
    } catch (error) {
      toast.error("Error occurred while Registering Student");
      console.error('Error sending form data:', error);
    }
    setLoading(false);
  };

  const getAttendanceData = async () => {
    try {
      const response = await axios.get(`${Config.BACKEND_URL}/attendance`);
      const uniqueNames = [...new Set(response.data.map(item => item.name))];
      console.log(response);
      const attendanceData = uniqueNames.map((name) => {
        return response.data.find(item => item.name === name);
      });
      setAttendance(attendanceData);
    } catch (error) {
      console.error('Error getting CSV data:', error);
    }
  };

  const markAbsent = async (studentName) => {
    try {
      const formData = new FormData();
      formData.append('student_name', studentName);
      const response = await axios.post(`${Config.BACKEND_URL}/mark-absent`, formData);
      getAttendanceData();
      toast.success("Marked Absent Successfully");
    } catch (err) {
      toast.error("Couldn't Mark Absent");
    }
  };

  return (
    <div className='bg-[#1B2124] text-white flex flex-col h-screen'>
      <div className='flex px-10 gap-8 pb-6 h-[calc(100vh-6rem)] flex-row'>
        <div className='max-w-[540px] py-10 rounded-xl px-24 bg-black w-full h-full flex flex-col items-center justify-center'>
          <div className='bg-[#1B2124] py-3 px-8 rounded-3xl'>
            <p>
              Present : <span className='text-[#1B7938] bg-[#ADCFB7] px-3 font-semibold py-1 rounded-2xl mr-2'>{attendance.filter((item) => item.attendance === 'Present').length}</span>
              Absent : <span className='text-[#D64545] bg-[#F0B3B2] px-3 font-semibold py-1 rounded-2xl'>{attendance.filter((item) => item.attendance === 'Absent').length}</span>
            </p>
          </div>
          <p className='text-center font-semibold text-lg my-5'>Facial Recognition Attendance</p>
          <div className='flex-1 bg-green-400 overflow-hidden mx-auto rounded-2xl relative'>
            <video className='w-full h-full object-cover rounded-xl transform -scale-x-100' ref={videoRef} autoPlay playsInline></video>
            <h3 className='absolute text-lg shadow-lg shadow-[#5A4BDA]/50 top-0 left-1/2 -translate-x-1/2 rounded-xl bg-white/80 rounded-t-none px-2 py-1 min-w-40 text-black text-center'>
              {student ? student.join(', ') : "Unknown"}
            </h3>
          </div>
          <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
          <form className='flex mt-5 w-full rounded-lg overflow-hidden border h-fit border-gray-300 items-center' onSubmit={(e) => { setLoading(true); e.preventDefault(); captureMultipleFrames(name); }}>
            <input type='text' placeholder='Enter Student Name' value={name} onChange={(e) => { setName(e.target.value) }} className='text-base flex-1 py-2.5 px-2 bg-transparent outline-none text-white h-full' />
            <button type='submit' placeholder='Enter Name' className='text-base text-blue-600 px-2'>{loading ? 'Adding...' : 'Add'}</button>
          </form>
          <p className='text-center text-sm my-2 text-gray-300'>Please stand in front of Webcam in a way such that your face must be clearly visible</p>
        </div>
        <div className='flex w-full pt-6 flex-wrap gap-4 overflow-y-auto max-h-full h-fit'>
          {attendance.map((item, index) => (
            <div key={index} className='bg-black w-[100px] h-[100px] rounded-lg relative'>
              <img src={`${Config.BACKEND_URL}/${item.name}/0.jpg`} alt={item.name.split('/').pop()} className='w-full h-full object-cover rounded-lg' />
              <div className='absolute bottom-0 gap-2 left-0 right-0 bg-transparent text-white px-1 pb-1 flex justify-between items-center'>
                <p className='text-xs bg-[#1B2124] bg-opacity-90 w-24 overflow-hidden px-2 py-0.5 rounded-lg line-clamp-1'>{item.name.split('/').pop()}</p>
                <span className={`text-xs ${item.attendance === 'Present' ? 'bg-[#1B7938]' : 'bg-[#D64545]'} w-8 flex items-center justify-center text-center py-0.5 rounded-full`}>{item.attendance?.split('')[0]}</span>
              </div>
            </div>
          ))}
          {attendance.length === 0 && <p className='text-white my-auto text-center w-full'>No Attendance Data</p>}
        </div>
      </div>
    </div>
  );
};

export default VideoCapture;
