import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import VideoCapture from "./components/VideoCapture";
import sendMongoAnalytics from "./utils/sendMongoAnalytics.tsx";
import Login from "./components/Login";

function App() {
  const [selectedClass, setSelectedClass] = useState("Class 10th A");
  const [schoolData, setSchoolData] = useState(null);

  const getSchoolData = async () => {
    // Fetch school data here
  };

  const handleSelectClass = (newClass) => {
    setSelectedClass(newClass);
  };

  useEffect(() => {
    sendMongoAnalytics("app_open", {});
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/home"
            element={
              <>
                <Navbar onSelectClass={handleSelectClass} />
                <VideoCapture selectedClass={selectedClass} />
              </>
            }
          />
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
